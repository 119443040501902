<template>
  <table v-if="!isLoading" class="table-item table table-info table-sm table-bordered mb-0 table-hover">
    <thead>
      <tr>
        <th width="50">#</th>
        <th>วันที่</th>
        <th>ประเภท</th>
        <th>อัตราจ่าย</th>
        <th width="8%">จ่าย</th>
        <th width="8%">หมายเลข</th>
        <th width="8%">ยอดเดิมพัน</th>
        <th width="8%">ส่วนลด</th>
        <th width="8%">รวม</th>
        <th width="8%">ถูกรางวัล</th>
        <th>สถานะ</th>
        <th>หมายเหตุ</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="item._id" :class="[{'alert-success':item.isWon}]">
        <td class="text-center">{{index+1}}</td>
        <td class="text-center">{{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}</td>
        <td class="text-center">{{item.text}}</td>
        <td class="text-center">{{item.rate.rateTitle}}</td>
        <td class="text-right">{{item.pay | amountFormat(2)}}</td>
        <td class="text-center text-primary">{{item.number}}</td>
        <td class="text-right text-success">{{item.amount | amountFormat(2)}}</td>
        <td class="text-right text-danger">-{{item.discount | amountFormat(2)}}</td>
        <td class="text-right text-success">{{item.credit | amountFormat(2)}}</td>
        <td>
          <div v-if="item.isWon===true" class="text-success text-right">{{item.reward | amountFormat(2)}}</div>
          <div v-else-if="item.isWon===false" class="text-danger text-center">ไม่ถูกรางวัล</div>
        </td>
        <td class="text-center"><NumberStatus :status="item.status" /></td>
        <td class="text-center">{{item.remarks}}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th colspan="5" class="text-center">รวม</th>
        <th class="text-center">{{summary.length}} รายการ</th>
        <th class="text-right text-success">{{summary.amount | amountFormat(2)}}</th>
        <th class="text-right text-danger">-{{summary.discount | amountFormat(2)}}</th>
        <th class="text-right text-success">{{summary.credit | amountFormat(2)}}</th>
        <th class="text-right text-success">{{summary.won | amountFormat(2, '')}}</th>
        <th></th>
        <th></th>
      </tr>
    </tfoot>
  </table>
</template>
<script>
import ReportService from "@/services/ReportService"
import Swal from 'sweetalert2'
import _ from 'lodash'
import cAlert from '@/helpers/alert'

export default {
  name: 'MemberBetNumbers',
  props: ['accountId', 'roundId'],
  data() {
    return {
      isLoading: false,
      data: null
    }
  },
  computed: {
    changed() {
      return `${this.roundId}-${this.accountId}`
    },
    items() {
      if(!this.data)
        return []

      return _.sortBy(this.data.items, [(item)=>{
        return {
          runTop: 1,
          runBottom: 2,
          twoNumberTop: 3,
          twoNumberBottom: 4,
          threeNumberTop: 5,
          threeNumberTode: 6,
        }[item.type]
      }, (item)=>{
        return parseInt(item.number)
      }])
    },
    summary() {
      return this.items.reduce((summary, item)=>{
        summary.amount += item.amount
        summary.credit += item.credit
        summary.discount += item.discount
        summary.length ++

        if(item.isWon) {
          summary.won += item.reward
        }

        return summary
      }, {
        amount: 0,
        credit: 0,
        discount: 0,
        won: 0,
        length: 0
      })
    }
  },
  watch: {
    changed() {
      this.getReport(this.accountId)
    }
  },
  methods: {
    getReport(accountId = null) {
      if(!this.roundId)
        return

      this.isLoading = true
      ReportService.getRoundMemberBetNumbers(this.roundId, accountId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.$emit('data', response.data)
          this.$emit('Breadcrumbs', response.data.agent.parents)
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ผิดพลาด!',
          text: 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.getReport(this.accountId)
  }
}
</script>
<style lang="scss" scoped>
table {
  thead {
    tr {
      th {
        text-align: center;
        font-size: 90%;
        padding: 0.25rem;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        font-size: 90%;
        vertical-align: middle;
        padding: 0.25rem;
      }
    }
  }
}
</style>
